<template>
    <div class="py-10 container">
        <div class="row m-0">
            <div class="col-md-6 col-12 p-0 mobile-app">
                <img v-lazy="getCompanyInfo.website_content.app_image" alt="chokrojan mobile app" class="mobile-app-img">
            </div>
            <div class="col-md-6 col-12 p-0 mobile-app-desc">
                <div>
                    <h2 class="text-blue" style="font-size: 45px;">{{getCompanyInfo.id==2 ? getCompanyInfo.name : "Chokrojan"}} Mobile App</h2>
                    <h3 class="pt-5" style="font-size: 25px; font-weight: normal;">Book your ticket using mobile app.</h3>
                    <h3 class="pt-2" style="font-size: 25px; font-weight: normal;">The app is now available in the Google play store.</h3>
                    <p style="line-height: 2.5; font-size: 16px;">You will love to use the {{ getCompanyInfo.id==2 ? getCompanyInfo.name : "Chokrojan" }} mobile app...</p>
                    <a v-if="getCompanyInfo.id==2" href="https://play.google.com/store/apps/details?id=com.chokrojan.robiexpress" target="_blank"><img alt="chokrojan mobile app available in google play store" src="https://chokrojan-bucket.s3.ap-southeast-1.amazonaws.com/company/images/getiton_google_play.png" width="40%"></a>
                    <a v-else href="https://play.google.com/store/apps/details?id=com.chokrojan.passenger" target="_blank"><img alt="chokrojan mobile app available in google play store" src="https://chokrojan-bucket.s3.ap-southeast-1.amazonaws.com/company/images/getiton_google_play.png" width="40%" height="auto"></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    export default {
        name: 'MobileApp',
        computed:{
            ...mapGetters(['getCompanyInfo'])
        }
    }
</script>

<style scoped>
.mobile-app{
    text-align: left;
}
.mobile-app-img{
    width: auto;
    height: 100%;
}
.mobile-app-desc{
    min-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media screen and (max-width: 768px) {
    .mobile-app{
        text-align: center;
    }
    .mobile-app-img{
        width: 100%;
    }
}
</style>
